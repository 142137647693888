import React, { useState } from 'react';
import { object, array } from 'prop-types';
import Swiper from 'react-id-swiper';
import { RichText } from 'prismic-reactjs';
import Button, { VARIANT } from '@components/Button/Button.js';
import IconButton, { VARIANT_ICON } from '@components/IconButton/IconButton.js';
import Modal from '@components/Modal';
import styles from './NewHero.module.scss';
import PLayIcon from '@src/assets/images/homepage/icons/play.inline.svg';
import ModalBookCall from '../../components/ModalBookCall/ModalBookCall';

const NewHero = ({ primary, items }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const handleOpenModal = () => setModalIsOpen(true);
  const handleCloseModal = () => setModalIsOpen(false);

  const {
    previewimage: previewImage,
    modalctabuttontext: modalCtaButtonText,
    modalctabuttonlink: modalCtaButtonLink,
    videobuttontext: videoButtonText,
    modalvideo: modalVideo,
    title,
    description,
    buttonlink,
    buttontext,
    secondbuttonlink,
    secondbuttontext,
  } = primary;

  const videoLink = modalVideo ? modalVideo.url : '';
  const params = {
    slidesPerView: 2,
    spaceBetween: 16,
    breakpoints: {
      768: {
        spaceBetween: 32,
      },
      992: {
        allowTouchMove: false,
        slidesPerView: 'auto',
      },
    },
  };

  const openCalendlyPopup = (e) => {
    e.preventDefault();
    setModalIsOpen(!modalIsOpen);
  };

  return (
    <div className={styles.hero}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            <RichText render={title.richText} />
          </div>
          <div className={styles.descr}>{description.text}</div>

          <div className={styles.buttons}>
            <div className={styles.buttonWrapper}>
              <Button variant={VARIANT.PRIMARY} to={secondbuttonlink.text}>
                {secondbuttontext.text}
              </Button>
              <div onClick={(e) => openCalendlyPopup(e)}>
                <Button variant={VARIANT.SOLUTION} to={'none'}>
                  {buttontext.text}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={previewImage?.url}
            alt="previewImage"
            loading="eager"
          />
          {videoLink && (
            <div className={styles.playButtonWrapper}>
              <div className={styles.playButton}>
                <IconButton variant={VARIANT_ICON.PLAY} click={handleOpenModal}>
                  <PLayIcon />
                </IconButton>
              </div>

              <div className={styles.playButtonText}>
                <p>{videoButtonText.text}</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className={styles.partners}>
        <Swiper {...params}>
          {items.map(({ partnerslogo }) => {
            return (
              <div className={styles.slide} key={partnerslogo.alt}>
                <img src={partnerslogo?.url} alt="partnerlogo" />
              </div>
            );
          })}
        </Swiper>
      </div>
      {videoLink && (
        <Modal
          open={modalIsOpen}
          closeModal={handleCloseModal}
          videoLink={videoLink}
          modalCtaButtonText={modalCtaButtonText.text}
          modalCtaButtonLink={modalCtaButtonLink.text}
        />
      )}
      <ModalBookCall
        open={modalIsOpen}
        calendlyLink={buttonlink.text}
        closeModal={handleCloseModal}
      />
    </div>
  );
};

NewHero.propTypes = {
  primary: object,
  items: array,
  handleMoveToWhatYouNeed: () => {},
};

export default NewHero;
