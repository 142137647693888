import React, { useEffect, useState } from 'react';
import { array, object } from 'prop-types';
import styles from './RelatedBlog.module.scss';
import { RichText } from 'prismic-reactjs';
import Articles from '../Articles/Articles';

const RelatedBlog = ({ primary, ArticleList, tagss }) => {
  const [isData, setIsData] = useState([]);

  const { title } = primary;

  const filteredArticles = ArticleList?.filter(({ node }) => {
    const searchData = JSON.stringify({
      tags: node?.tags,
    }).toLowerCase();

    for (let i = 0; i < tagss.length; i++) {
      if (searchData.includes(tagss[i].toLowerCase())) {
        return searchData.includes(tagss[i].toLowerCase());
      }
    }
  });

  useEffect(() => {
    if (filteredArticles.length === 0) {
      setIsData(ArticleList);
    } else {
      setIsData(filteredArticles);
    }
  }, []);

  return (
    <section className={styles.relatedBlog}>
      <div className={styles.container}>
        <div className={styles.title}>
          <RichText render={title.richText} />
        </div>
        <div className={styles.blogPreview}>
          <Articles filteredArticles={isData} />
        </div>
      </div>
    </section>
  );
};

RelatedBlog.propTypes = {
  node: object,
  primary: object,
  ArticleList: array,
  tagss: array,
};

export default RelatedBlog;
